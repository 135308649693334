export default [
	// 产品
	{
		path: '/product/hotProducts',
		name: 'HotProducts', // 热门产品
		component: () => import('@/pages/product/HotProducts.vue')
	},
	{
		path: '/product/productDetail',
		name: 'ProductDetail', // 产品详情
		component: () => import('@/pages/product/ProductDetail.vue')
	},
	{
		path: '/product/category',
		name: 'Category', // 分类页
		component: () => import('@/pages/product/Category.vue')
	},
]