export default [
	// 其他页面
	{
		path: '/productInfo',
		name: 'ProductInfo', // 产品资料下载
		component: () => import('@/pages/other/ProductInfo.vue')
	},
	{
		path: '/collectList',
		name: 'CollectList', // 我的收藏
		component: () => import('@/pages/other/CollectList.vue')
	},
	{
		path: '/helpCenter',
		name: 'HelpCenter', // 帮助中心
		component: () => import('@/pages/other/HelpCenter.vue')
	},
	{
		path: '/cartList',
		name: 'CartList', // 购物车
		component: () => import('@/pages/other/CartList.vue')
	},
	{
		path: '/orderQuickly',
		name: 'OrderQuickly', // 快速下单
		component: () => import('@/pages/other/OrderQuickly/index.vue')
	},
	{
		path: '/placeOrder',
		name: 'PlaceOrder', // 提交订单
		component: () => import('@/pages/other/placeOrder/PlaceOrder.vue')
	},
	{
		path: '/orderOver',
		name: 'OrderOver', // 订单完成
		component: () => import('@/pages/other/placeOrder/OrderOver.vue')
	},
	{
		path: '/orderDetail',
		name: 'OrderDetail', // 订单详情
		component: () => import('@/pages/other/OrderDetail.vue'),
	},
]

