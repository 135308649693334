const zh = {
	// 通用
	serial_number: '序号',
	please_enter: '请输入',
	please_select: '请选择',
	determine: '确定',
	cancel: '取消',
	required: '必填项',
	notRequired: '非必填项',
	empty: '清空',
	close: '关闭',
	submit: '提交',
	ElMessage_success: '操作成功',
	ElMessage_add: '添加成功',
	ElMessage_cancel: '取消成功',
	ElMessage_requir: '请填写必填项',
	ElMessage_delSuccess: '删除成功',
	ElMessage_setSuccess: '设置成功',
	ElMessage_editSuccess: '修改成功',
	ElMessage_uploadSuccess: '上传成功',
	ElMessage_quicklyUploadFail: '不存在或还未上架',
	ElMessage_orderNot: '货号不存在',
	ElMessage_uname: '请输入用户名',
	
	// login  登录 / 注册 
	login_register_title: '注册您的账户-它非常简单快捷！',
	login_register_tips: '我们为能为客户提供方便的在线订购的高级选项而感到自豪。无论您是采购代理，安全员还是个人购买者，我们都为您和您的组织提供了可优化您的购买体验的工具',
	login_register_li1: "实时产品可用性",
	login_register_li2: "客户特定价格",
	login_register_li3: "订单历史",
	login_register_li4: "收藏夹",
	login_register_li5: "实时订单状态",
	login_register_li6: "立即订单处理",
	login_register_consistent: "密码和验证密码请确保一致",
	login_register_approved: "该用户名正在审核",
	login_register_approved2: "该用户正在审核中",
	login_register_registered: "该用户名已注册",
	login_register_succeeded: "注册成功！请等待审核",
	login_login_title: '登录您的账户',
	login_login_forget: '忘记密码?',
	login_login_notRegister: '不是注册用户？',
	login_login_create: '创建一个账户',
	forgetPassword_verificaCode: '验证码',
	forgetPassword_getCode: '获取验证码',
	forgetPassword_tips: '验证码将发送到您注册的电子邮箱',
	forgetPassword_seconds: '秒后重新发送',
	forgetPassword_backLogin: '返回登录页面',
	login_login_forgetPassword: '忘记密码',

	// header  头部
	header_Language: 'English',
	header_collectList: '我的收藏',
	header_helpCenter: '帮助中心',
	header_cart: '购物车',
	header_popularProducts: '热门产品',
	header_memberCenter: '会员中心',
	header_myOrder: '我的订单',
	header_quickOrder: '快速下单',
	header_Product_download: '产品资料下载',
	header_security_center: '安全中心',
	header_register: '注册',
	header_login: '登录',
	header_search: '搜索',
	header_scategories: '搜索指定分类',
	header_search_content: '请输入您要搜索内容',
	
	// home  首页
	home_home: '首页',
    home_product_category: '产品分类',
    home_product_recommend: '产品推荐',
	home_links: '友情链接',
	home_online_service: '在线客服',
	home_back_to_top: '回到顶部',

	// ProductInfo 产品资料下载
	ProductInfo_file_name: '文件名',
	productInfo_release_time: '发布时间',
	productInfo_download: '下载',

	// MemberCenter  会员中心
	MemberCenter_good_morning: '上午好',
	MemberCenter_good_afternoon: '下午好',
	MemberCenter_good_evening: '晚上好',
	MemberCenter_addjJobTitle: '添加工作职位',
	MemberCenter_recentOrders: '最近订单',
	MemberCenter_all_orders: '全部订单',
	MemberCenter_under_review: '审核中',
	MemberCenter_to_be_shipped: '待发货',
	MemberCenter_shipped: '已发货',
	MemberCenter_invoicing: '已收货/开票',
	MemberCenter_received: '已收货',
	MemberCenter_invoiced: '已开票',
	MemberCenter_return_goods: '已退货',
	MemberCenter_return: '退货中',
	MemberCenter_member_info: '会员信息',
	MemberCenter_personal_data: '个人资料',
	MemberCenter_receiving_address: '收货地址',
	MemberCenter_invoice_information: '发票信息',
	MemberCenter_change_password: '更改密码',
	MemberCenter_log_out: '退出登录',
	MemberCenter_contact_info: '联系信息',
	MemberCenter_country: '国家',
	MemberCenter_name: '名',
	MemberCenter_surname: '姓',
	MemberCenter_login_info: '登录信息',
	MemberCenter_user_name: '用户名（电子邮件）',
	MemberCenter_company_info: '公司信息',
	MemberCenter_company: '公司',
	MemberCenter_address1: '地址1',
	MemberCenter_address2: '地址2',
	MemberCenter_password: '密码',
	MemberCenter_verify_password: '验证密码',
	MemberCenter_city_town: '城市/城镇',
	MemberCenter_province: '省份',
	MemberCenter_zip_code: '邮政编码',
	MemberCenter_telephone: '电话',
	MemberCenter_fax: '传真',
	MemberCenter_need: '需要',
	MemberCenter_agree: '我同意所述条款条件',
	MemberCenter_terms: '条款和内容',
	MemberCenter_tips: '请点击注册创建您的账户。如果您希望设置默认的发货和账单地址，您可在之后的页面中看到相应的选项。您也可以随时在会员中心更改这些设置。',
	MemberCenter_MemberId: '会员ID',
	MemberCenter_MemberType: '会员类型',
	MemberCenter_MemberGrade: '会员等级',
	MemberCenter_payment: '付款方式',
	MemberCenter_receipt: '确认收货',
	MemberCenter_shipToday: '下单后预计今天发货',
	MemberCenter_youPrice: '您的价格',
	
	// 我的订单
	MemberCenter_orderNumber: '订单编号',
	MemberCenter_orderTime: '下单时间',
	MemberCenter_paymentStatus: '付款情况',
	MemberCenter_paymentAmount: '支付金额（CNY）',
	MemberCenter_paymentTime: '支付时间',
	MemberCenter_paymentNumber: '付款账号',
	MemberCenter_addPay: '添加支付凭证',
	MemberCenter_addVoucher: '上传凭证',
	MemberCenter_photos: '上传照片',
	MemberCenter_situation: '审核情况',
	MemberCenter_tracking: '物流追踪',
	MemberCenter_retrunRequest: '退货申请',
	MemberCenter_retrunTime: '退货申请时间',
	MemberCenter_reviewed: '待审核',
	MemberCenter_approved: '审核通过',
	MemberCenter_reject: '驳回',
	MemberCenter_rejected: '审核驳回',
	MemberCenter_logistics: '物流号码',
	MemberCenter_logisticsCompany: '物流公司',
	MemberCenter_service: '客服号码',
	MemberCenter_forReturn: '申请退货',
	MemberCenter_returnTips: '请先与客服沟通，双方同意后再申请退货。',
	MemberCenter_reason: '退货原因',
	MemberCenter_waybill: '运单号码',
	MemberCenter_waybill2: '快递公司及运单号码',
	MemberCenter_again: '再考虑一下',
	MemberCenter_confirmReturn: '确认退货',
	MemberCenter_moreOrder: '再来一单',
	MemberCenter_notWant: '不是我想要的产品。',
	MemberCenter_wrongGoods: '商家发错货了。',
	MemberCenter_damaged: '产品包装破损。',
	MemberCenter_other: '其他',
	MemberCenter_enterAmount: '请输入支付金额',
	MemberCenter_selectTime: '请选择支付时间',
	MemberCenter_enterNumber: '请输入付款账号',
	MemberCenter_uploadVoucher: '请上传凭证',
	MemberCenter_goodsSuccess: '收货成功',
	MemberCenter_preferential: '优惠价',
	MemberCenter_totalPrice: '总价',
	MemberCenter_productName: '商品名称',
	
	// 收获地址
	MemberCenter_addAddress: '请添加您的常用收货地址。',
	MemberCenter_default: '默认',
	MemberCenter_addNew: '添加新地址',
	MemberCenter_setDefault_address: '设为默认地址',
	MemberCenter_delAddress: '删除该地址',
	MemberCenter_region: '地区',
	MemberCenter_address: '地址',
	MemberCenter_addressee: '收件人',
	MemberCenter_contact: '联系电话',

	// 发票信息
	MemberCenter_information: '请添加您的常用发票信息。',
	MemberCenter_addInformation: '添加发票信息',
	MemberCenter_type: '抬头类型',
	MemberCenter_rise: '抬头',
	MemberCenter_paragraph: '税号',
	MemberCenter_companyAddress: '单位地址',
	MemberCenter_deposit: '开户银行',
	MemberCenter_account: '银行账户',
	MemberCenter_personal: '个人',
	MemberCenter_delRise: '删除该抬头',
	MemberCenter_setDefault: '设为默认抬头',
	MemberCenter_invaddress: '发票收件地址',
	MemberCenter_invaddressee: '发票收件人',
	MemberCenter_invmail: '电子邮箱',
	MemberCenter_invoiceNo: '发票编号',

	// 更改密码
	MemberCenter_oldPassword: '请输入原密码',
	MemberCenter_newPassword: '请输入新密码',
	MemberCenter_againPassword: '请再次输入新密码',
	MemberCenter_modification: '确认修改',
	MemberCenter_inputPassword: '请输入密码',
	MemberCenter_keepPassword: '新密码请保持一致',
	MemberCenter_originalError: '原密码错误',
	
	// OrderQuickly 快速下单
	OrderQuickly_tips: '选择一种最适合您需求的订购方式, 快速便捷的将产品加入购物车',
	OrderQuickly_enterOrder: '输入货号下单',
	OrderQuickly_enterOne: '一次输入一个产品',
	OrderQuickly_enterbatch: '批量输入货号下单',
	OrderQuickly_uploadProduct: '上传多个产品',
	OrderQuickly_keywordOrder: '关键字下单',
	OrderQuickly_enterProduct: '输入多个产品',
	OrderQuickly_enterNeed: '输入要订购的产品货号',
	OrderQuickly_enterReservation: '输入预留单号从特性批次中获取产品',
	OrderQuickly_add5: '再添加5行',
	OrderQuickly_tips2: '在提供的模板中输入产品信息（最多350个产品），然后上传模板文件。 产品将自动添加到您的购物车。 为了加快加载速度，建议一个文件中上传50个产品。 您可以根据需要上传多个文件。',
	OrderQuickly_download: '下载模板',
	OrderQuickly_dragUpload: '拖拽文件上传',
	OrderQuickly_selectFile: '选择文件',
	OrderQuickly_support: '支持：.xls或者.xlsx',
	OrderQuickly_tips3: '在下面的框中最多粘贴350个货号。 将货号与其他货号分开，并用回车键隔开。 请参阅下面的示例的格式。 数量将默认为“ 1”。 将物品添加到购物车后，您可以更新数量。 为了加快加载速度，建议一次粘贴50种产品到文本框中。',
	OrderQuickly_example: '举例',
	OrderQuickly_enterNumber: '请输入货号',
	OrderQuickly_enterOneNumber: '请输入至少一个货号',

	// 热门产品 HotProducts
	HotProducts_resources: '技术资源',
	HotProducts_sort: '排序方式',
	HotProducts_sortclassification: '相关分类',
	HotProducts_criteria: '筛选条件',
	HotProducts_expand: '全部展开',
	HotProducts_collapse: '全部折叠',
	HotProducts_productName: '产品品牌名称',
	HotProducts_technology: '技术类型',
	HotProducts_compatible: '型号兼容',
	HotProducts_capacity: '体积容量',
	HotProducts_addCollect: '添加收藏',
	HotProducts_cancelCollect: '取消收藏',
	HotProducts_checkAdd: '请勾选需要添加的商品',
	HotProducts_enterAdd: '请输入需要添加商品的数量',

	// 提交订单 placeOrder
	placeOrder_placeOrder: '提交订单',
	placeOrder_unit: '含税单价（CNY）',
	placeOrder_remark: '备注',
	placeOrder_addressTips: '请选择或填写您的收货地址',
	placeOrder_invoiceTips: '请选择发票类型',
	placeOrder_invoiceType: '发票类型',
	placeOrder_special: '增值税专用发票',
	placeOrder_ordinary: '增值税普通发票',
	placeOrder_select: '选中',
	placeOrder_selectMethod: '请选择付款方式',
	placeOrder_selectaddress: '请选择收货地址',
	placeOrder_selectinvoice: '请选择发票类型',
	placeOrder_selectinvoiceInfo: '请选择发票信息',
	placeOrder_article1: '货号为',
	placeOrder_article2: '的产品库存不足',
	placeOrder_pendingReview: '订单提交成功, 请耐心等待审核',
	placeOrder_reject: '驳回原因',
	MemberCenter_gotoMyOrder: '前往查看我的订单',
	MemberCenter_returnCart: '返回购物车',
	MemberCenter_original: '原价（CNY）',
	MemberCenter_membership: '会员价',
}
export default zh