import {createRouter, createWebHistory} from "vue-router"
import memberCenter from "@/router/modules/memberCenter"
import other from "@/router/modules/other"
import product from "@/router/modules/product"

export const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			redirect: '/home',
		},
		{
			path: '/login',
			name: 'Login', // 登录页
			component: () => import('../pages/login/Login.vue')
		},
		{
			path: '/register',
			name: 'Register', // 注册页
			component: () => import('../pages/login/Register.vue')
		},
		{
			path: '/forgetPassword',
			name: 'ForgetPassword', // 忘记密码
			component: () => import('../pages/login/forgetPassword.vue')
		},
		{
			path: '/home',
			name: 'home', // 首页
			component: () => import('../pages/Home.vue')
		},
		{
			path: '/testDom',
			name: 'testDom', // 测试
			component: () => import('../pages/testDom.vue')
		},
		...memberCenter,
		...other,
		...product,
		{
			path: '/:catchAll(.*)',
			name: 'Error',
			redirect: '/',
		}
	]
})

// 全局路由守卫
// router.beforeEach((to, from, next) => {
//     next()
// })