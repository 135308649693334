export default [
	{
		path: '/memberCenter',
		redirect: '/memberCenter/latelyOrder'
	},
	{
		path: '/memberCenter/mainCounter',
		name: 'MainCounter', // 个人中心
		redirect: '/memberCenter/latelyOrder',
		component: () => import('@/pages/memberCenter/MainCounter.vue'),
		children: [
			{
				path: '/memberCenter/latelyOrder',
				name: 'latelyOrder', // 最近订单
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/myOrder',
				name: 'MyOrder', // 全部订单
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/underReview',
				name: 'UnderReview', // 审核中
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/consignment',
				name: 'Consignment', // 待发货
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/shipped',
				name: 'Shipped', // 已发货
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/received',
				name: 'Received', // 已收款/开票
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/refunding',
				name: 'Refunding', // 退货中
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/refund',
				name: 'Refund', // 已退货
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/reject',
				name: 'Reject', // 驳回
				component: () => import('@/pages/memberCenter/MyOrder.vue'),
			},
			{
				path: '/memberCenter/infoData',
				name: 'InfoData', // 个人资料
				component: () => import('@/pages/memberCenter/InfoData.vue'),
			},
			{
				path: '/memberCenter/addRess',
				name: 'AddRess', // 收货地址
				component: () => import('@/pages/memberCenter/AddRess.vue'),
			},
			{
				path: '/memberCenter/invoice',
				name: 'Invoice', // 发票信息
				component: () => import('@/pages/memberCenter/Invoice.vue'),
			},
			{
				path: '/memberCenter/editPassword',
				name: 'EditPassword', // 更改密码
				component: () => import('@/pages/memberCenter/EditPassword.vue'),
			},
		]
	},
]